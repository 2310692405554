import * as React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

const ComboBox = ({
  options,
  label,
  value,
  onChange,
  getOptionLabel,
  isOptionEqualToValue,
  loading = false,
  fullWidth = true,
  disabled = false,
}) => {
  return (
    <Autocomplete
      disablePortal
      options={options || []}
      value={value}
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      loading={loading}
      fullWidth={fullWidth}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          fullWidth={fullWidth}
        />
      )}
    />
  )
}

export default ComboBox
