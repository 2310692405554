import { useState, useEffect } from "react"
import useFetchWithMsal from "../../hooks/useFetchWithMsal"
import LinearProgress from "@mui/material/LinearProgress"
import { Box, Button, Dialog, Stack, Select, MenuItem } from "@mui/material"
import "../../styles/B2B.css"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { useNavigate } from "react-router-dom"
import { DataGrid } from "@mui/x-data-grid"
import { CustomToolbar } from "../../components/CustomToolbar"
import CreateDemand from "../../components/CreateDemand"
import { Breadcrumbs } from "../../components/Breadcrumbs"
import WorkDetail from "../../components/WorkDetail"
import Page from "../../components/Page"
import { FormControl, InputLabel } from "@mui/material"
const DEMAND_LIST_URL = "/api/demand?type=B2B"
const BLEND_LIST_URL = "/api/blends"

export const B2BSalesTable = () => {
  const navigate = useNavigate()
  const [newDemand, setNewDemand] = useState(null)
  const [selectedDemand, setSelectedDemand] = useState(null)
  const [rows, setRows] = useState([])
  const [selectedBomEdit, setSelectedBomEdit] = useState(null)
  const [selectedBom, setSelectedBom] = useState(null)

  const { execute: executePostNewDemand, isLoading: newDemandLoading } =
    useFetchWithMsal()
  const {
    execute: executeGetDemands,
    isLoading: listLoading,
    data: demandsList,
  } = useFetchWithMsal()

  const { execute: executeGetBlends, data: blendsList } = useFetchWithMsal()

  const [columnVisibility, setColumnVisibility] = useState({
    contractVintage: false,
    variety: false,
    appellation: false,
    customer: true,
    blend: true,
    gallons: true,
    pricePerGallon: true,
    costPerGallon: true,
    grossMargin: true,
    revenue: false,
    cogs: false,
    grossProfit: false,
    contract: true,
    bom: true,
  })

  useEffect(() => {
    executeGetDemands("GET", DEMAND_LIST_URL)
  }, [executeGetDemands])

  useEffect(() => {
    if (demandsList) {
      setRows(demandsList)
    }
  }, [demandsList])

  useEffect(() => {
    executeGetBlends("GET", BLEND_LIST_URL)
  }, [executeGetBlends])

  const handleToggleWineDetails = () => {
    setColumnVisibility((prev) => ({
      ...prev,
      contractVintage: !prev.contractVintage,
      variety: !prev.variety,
      appellation: !prev.appellation,
    }))
  }

  const handleToggleFinancials = () => {
    setColumnVisibility((prev) => ({
      ...prev,
      revenue: !prev.revenue,
      cogs: !prev.cogs,
      grossProfit: !prev.grossProfit,
    }))
  }

  const handleCreateNewDemand = async (newDemandInput) => {
    try {
      const response = await executePostNewDemand(
        "POST",
        DEMAND_LIST_URL,
        newDemandInput
      )
      if (response) {
        setNewDemand(null)
        // Ensure we get fresh data after creating new demand
        await executeGetDemands("GET", DEMAND_LIST_URL)
      }
    } catch (error) {
      console.error("Error creating new demand:", error)
    }
  }

  const handleUpdateBom = async (newBom) => {
    try {
      const response = await executePostNewDemand(
        "POST",
        "/api/demand/bom",
        newBom
      )
      if (response) {
        setSelectedBomEdit(null)
        await executeGetDemands("GET", DEMAND_LIST_URL)
      }
    } catch (error) {
      console.error("Error updating bom:", error)
    }
  }

  const columns = [
    {
      field: "customer",
      headerName: "Customer",
      width: 180,
      valueGetter: (params) =>
        params.row.externalSalesContract?.customerName || params.row.customer,
    },
    {
      field: "contract",
      headerName: "Contract",
      width: 100,
      valueGetter: (params) =>
        params.row.externalSalesContract?.contract || "-",
    },
    {
      field: "contractBlend",
      headerName: "Blend",
      width: 150,
      valueGetter: (params) =>
        params.row.externalSalesContract?.blend || params.row.contractBlend,
    },
    {
      field: "bom",
      headerName: "BOM",
      width: 150,
      renderCell: (params) => (
        <div
          style={{
            cursor: "pointer",
            padding: "3px",
            backgroundColor: "#f5f5f5",
          }}
          onClick={() => setSelectedBomEdit(params.row)}
        >
          {params.row?.boms?.[0]?.blend?.name || "No Blend Assigned"}
        </div>
      ),
    },

    {
      field: "contractVintage",
      headerName: "Vintage",
      width: 130,
      cellClassName: "secondary-column",
      headerClassName: "secondary-column",
      valueGetter: (params) => {
        const vintage =
          params.row.externalSalesContract?.vintage ||
          params.row.contractVintage
        if (vintage) return vintage
        const blend =
          params.row.externalSalesContract?.blend || params.row.contractBlend
        return blend ? blend.substring(0, 2) : ""
      },
    },
    {
      field: "variety",
      headerName: "Variety",
      width: 180,
      cellClassName: "secondary-column",
      headerClassName: "secondary-column",
      valueGetter: (params) => {
        const blend =
          params.row.externalSalesContract?.blend || params.row.contractBlend
        return blend ? blend.substring(2, 5) : ""
      },
    },
    {
      field: "appellation",
      headerName: "Appellation",
      width: 180,
      cellClassName: "secondary-column",
      headerClassName: "secondary-column",
      valueGetter: (params) => {
        const blend =
          params.row.externalSalesContract?.blend || params.row.contractBlend
        return blend ? blend.substring(5, 7) : ""
      },
    },
    {
      field: "contractVolume",
      headerName: "Gallons",
      width: 130,
      type: "number",
      valueGetter: (params) =>
        params.row.externalSalesContract
          ? params.row.externalSalesContract.contractGallons
          : params.row.contractVolume,
    },
    {
      field: "contractPrice",
      headerName: "Price/Gallon",
      width: 130,
      type: "number",
      valueGetter: (params) =>
        params.row.externalSalesContract
          ? params.row.externalSalesContract.price
          : params.row.contractPrice,
      valueFormatter: (params) => `$${params.value.toFixed(2)}`,
    },
    {
      field: "costPerGallon",
      headerName: "Cost/Gallon",
      width: 130,
      type: "number",
      valueGetter: (params) => params.row.costPerGallon || 0,
      valueFormatter: (params) => `$${params.value.toFixed(2)}`,
    },
    {
      field: "grossMargin",
      headerName: "Gross Margin",
      width: 130,
      valueGetter: (params) => {
        const price = params.row.contractPrice
        const cost = params.row.costPerGallon
        if (!price || !cost) return "-"
        const margin = ((price - cost) / price) * 100
        return `${margin.toFixed(1)}%`
      },
    },
    {
      field: "revenue",
      headerName: "Revenue",
      width: 130,
      type: "number",
      cellClassName: "secondary-column",
      headerClassName: "secondary-column",
      valueGetter: (params) => {
        const volume = params.row.externalSalesContract
          ? params.row.externalSalesContract.contractGallons
          : params.row.contractVolume
        const price = params.row.externalSalesContract
          ? params.row.externalSalesContract.price
          : params.row.contractPrice
        return volume * price
      },
      valueFormatter: (params) => `$${params.value.toFixed(2)}`,
    },
    {
      field: "cogs",
      headerName: "COGS",
      width: 130,
      type: "number",
      cellClassName: "secondary-column",
      headerClassName: "secondary-column",
      valueGetter: (params) => {
        const volume = params.row.externalSalesContract
          ? params.row.externalSalesContract.contractGallons
          : params.row.contractVolume
        const cost = params.row.costPerGallon || 0
        return volume * cost
      },
      valueFormatter: (params) => `$${params.value.toFixed(2)}`,
    },
    {
      field: "grossProfit",
      headerName: "Gross Profit",
      width: 130,
      type: "number",
      cellClassName: "secondary-column",
      headerClassName: "secondary-column",
      valueGetter: (params) => {
        const volume = params.row.externalSalesContract
          ? params.row.externalSalesContract.contractGallons
          : params.row.contractVolume
        const price = params.row.externalSalesContract
          ? params.row.externalSalesContract.price
          : params.row.contractPrice
        const cost = params.row.costPerGallon || 0
        return volume * price - volume * cost
      },
      valueFormatter: (params) => `$${params.value.toFixed(2)}`,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <Button
          size="small"
          variant="outlined"
          onClick={() => setSelectedDemand({ type: "edit", data: params.row })}
        >
          Edit
        </Button>
      ),
    },
    {
      field: "work",
      headerName: "Work",
      width: 100,
      renderCell: (params) => (
        <Button
          size="small"
          variant="outlined"
          onClick={() => setSelectedDemand({ type: "work", data: params.row })}
        >
          Work ({params.row.plannedWorks?.length || 0})
        </Button>
      ),
    },
  ]

  return (
    <Page fullHeight className="B2B">
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Breadcrumbs />
        {(newDemandLoading || listLoading) && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}

        <Box sx={{ py: 1 }}>
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            <Button
              size="small"
              variant="outlined"
              onClick={() => setNewDemand({})}
            >
              NEW DEMAND
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={() => navigate("/work/categories")}
              endIcon={<OpenInNewIcon />}
            >
              Work
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={() => navigate("/demand/B2B/approvals")}
              endIcon={<OpenInNewIcon />}
            >
              Approvals
            </Button>
          </Stack>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            minHeight: 0,
          }}
        >
          <Box
            sx={{
              flex: 1,
              minHeight: 0,
              display: "flex",
            }}
            className="body"
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                flex: 1,
                maxHeight: "100%",
              }}
              className="gridContainer"
            >
              <DataGrid
                rows={rows}
                columns={columns}
                disableRowSelectionOnClick
                rowBuffer={10}
                rowHeight={40}
                hideFooterPagination
                disablePagination
                columnVisibilityModel={columnVisibility}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibility(newModel)
                }
                components={{
                  Toolbar: CustomToolbar,
                }}
                componentsProps={{
                  toolbar: {
                    columnVisibility,
                    onToggleWineDetails: handleToggleWineDetails,
                    onToggleFinancials: handleToggleFinancials,
                  },
                }}
                sx={{
                  flex: 1,
                  bgcolor: "background.paper",
                  borderRadius: 1,
                  boxShadow: 2,
                  "& .MuiDataGrid-cell": {
                    px: 2,
                  },
                  "& .secondary-column": {
                    color: "#666",
                    fontStyle: "italic",
                    backgroundColor: "#f5f5f5",
                    "& .MuiDataGrid-cellContent": {
                      paddingRight: "8px",
                      justifyContent: "flex-end",
                    },
                    "&:hover": {
                      color: "#000",
                      backgroundColor: "#f5f5f5",
                    },
                  },
                  "& .MuiDataGrid-columnHeader.secondary-column": {
                    color: "#666",
                    fontStyle: "italic",
                    backgroundColor: "#f5f5f5",
                  },
                }}
              />
            </Box>
          </Box>

          {selectedDemand && (
            <div className="selectedCont">
              {selectedDemand.type === "work" && (
                <WorkDetail
                  demand={selectedDemand.data}
                  close={() => setSelectedDemand(null)}
                />
              )}
            </div>
          )}
        </Box>

        {/* Create Demand Dialog */}
        <Dialog
          fullWidth
          open={
            !!newDemand || !!(selectedDemand && selectedDemand.type === "edit")
          }
          onClose={() => {
            setNewDemand(null)
            setSelectedDemand(null)
          }}
        >
          <Box sx={{ p: 2 }}>
            <CreateDemand
              newDemand={
                selectedDemand?.type === "edit" ? selectedDemand.data : null
              }
              handleCreateNewDemand={handleCreateNewDemand}
              close={() => {
                setNewDemand(null)
                setSelectedDemand(null)
              }}
            />
          </Box>
        </Dialog>

        {selectedBomEdit && (
          <Dialog
            fullWidth
            maxWidth="sm"
            open={!!selectedBomEdit}
            onClose={() => {
              setSelectedBomEdit(null)
              setSelectedBom(null)
            }}
          >
            <Box sx={{ p: 2 }}>
              <div>
                Blend for{" "}
                {selectedBomEdit?.externalSalesContract?.customerName ||
                  selectedBomEdit?.customer}{" "}
                -{" "}
                {selectedBomEdit?.externalSalesContract?.blend ||
                  selectedBomEdit?.contractBlend}
              </div>
              <div>
                Current Blend:{" "}
                {selectedBomEdit?.boms?.[0]?.blend?.name || "None"}
              </div>
              <div style={{ marginTop: 10 }}>New Blend:</div>
              <FormControl fullWidth>
                <InputLabel id="blend-label">Blend</InputLabel>
                <Select
                  labelId="blend-label"
                  value={selectedBom?.id || ""}
                  label="Blend"
                  onChange={(e) => {
                    const blend = blendsList.find(
                      (blend) => blend.id === e.target.value
                    )
                    setSelectedBom(blend)
                  }}
                >
                  {blendsList?.map((blend) => (
                    <MenuItem key={blend.id} value={blend.id}>
                      {blend.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {selectedBom && (
                <div style={{ marginTop: 10 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleUpdateBom({
                        demandId: selectedBomEdit.id,
                        blendId: selectedBom.id,
                        bomPeriods: selectedBomEdit.boms?.[0]?.bomPeriods || [],
                      })
                      setSelectedBomEdit(null)
                      setSelectedBom(null)
                    }}
                  >
                    Save
                  </Button>
                </div>
              )}
            </Box>
          </Dialog>
        )}
      </Box>
    </Page>
  )
}
