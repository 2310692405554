import React from "react"
import {
  Button,
  Stack,
  TextField,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  Divider,
  Paper,
  Typography,
  Link,
} from "@mui/material"
import { useState, useEffect } from "react"
import useFetchWithMsal from "../hooks/useFetchWithMsal"
import ComboBox from "./ComboBox"
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/Delete"
import AttachFileIcon from "@mui/icons-material/AttachFile"

const defaultHeader = {
  id: null,
  date: new Date().toISOString().split("T")[0],
  customer: null,
  files: [],
  status: "Approved",
}

const defaultDetailItem = {
  id: null,
  contract: null,
  contractBlend: null,
  approvalGallons: 0,
  blendDetails: [], // Array of blend detail items
}

const defaultBlendDetail = {
  id: null,
  tank: "",
  blend: "",
  lot: "",
  percentage: 0,
}

const CreateApproval = ({
  approval: providedApproval,
  handleSaveApproval,
  close,
  disabled,
}) => {
  const [header, setHeader] = useState(
    providedApproval?.header || defaultHeader
  )
  const [detailItems, setDetailItems] = useState(
    providedApproval?.detailItems || []
  )
  const [selectedItemIndex, setSelectedItemIndex] = useState(0)
  const [uploadedFiles, setUploadedFiles] = useState([])
  const fileInputRef = React.useRef(null)

  const {
    execute: executeGetContracts,
    data: contracts,
    isLoading: contractsLoading,
  } = useFetchWithMsal()

  useEffect(() => {
    if (providedApproval) {
      setHeader({
        id: providedApproval.id,
        date: providedApproval.date,
        customer: { name: providedApproval.customer },
        status: providedApproval.status,
      })

      setDetailItems(
        providedApproval.approvalLines.map((line) => ({
          id: line.id,
          contract: contracts?.find(
            (contract) => contract.id === line.externalSalesContractId
          ),
          approvalGallons: line.gallons,
          blendDetails: line.approvalLineInventories.map((inventory) => ({
            id: inventory.id,
            tank: inventory.tank,
            blend: inventory.blend,
            lot: inventory.lot,
            percentage: inventory.percentage,
          })),
        }))
      )
    }
  }, [contracts, providedApproval])

  useEffect(() => {
    if (!contracts && !contractsLoading) {
      executeGetContracts("GET", "/api/demand/contracts")
    }
  }, [contracts, contractsLoading, executeGetContracts])

  const handleFileChange = async (event) => {
    const newFiles = Array.from(event.target.files)

    const filePromises = newFiles.map((file) => {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onload = (e) => {
          resolve({
            name: file.name,
            content: e.target.result,
            size: file.size,
            type: file.type,
          })
        }
        reader.readAsDataURL(file)
      })
    })

    // Wait for all files to be processed
    const processedFiles = await Promise.all(filePromises)

    // Update state
    setUploadedFiles((prevFiles) => [...prevFiles, ...processedFiles])
    setHeader((prev) => ({
      ...prev,
      files: [...(prev.files || []), ...processedFiles],
    }))

    // Reset file input
    if (fileInputRef.current) {
      fileInputRef.current.value = ""
    }
  }

  const handleRemoveFile = (indexToRemove) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    )
    setHeader((prev) => ({
      ...prev,
      files: prev.files.filter((_, index) => index !== indexToRemove),
    }))
  }

  const formatFileSize = (bytes) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const sizes = ["Bytes", "KB", "MB", "GB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i]
  }

  const truncateFileName = (fileName, maxLength = 45) => {
    if (fileName.length <= maxLength) return fileName

    const extension = fileName.split(".").pop()
    const nameWithoutExt = fileName.slice(0, fileName.lastIndexOf("."))

    // Reserve characters for the extension plus dots and ellipsis
    const reservedLength = extension.length + 6 // ... + . + extension
    const truncatedLength = maxLength - reservedLength

    return `${nameWithoutExt.slice(0, truncatedLength)}...${extension}`
  }

  const handleAddDetailItem = () => {
    setDetailItems([...detailItems, { ...defaultDetailItem }])
    setSelectedItemIndex(detailItems.length + 1)
  }

  const handleDeleteDetailItem = (index) => {
    const newItems = detailItems.filter((_, i) => i !== index)
    setDetailItems(newItems)
    setSelectedItemIndex(0)
  }

  const handleAddBlendDetail = (itemIndex) => {
    const newItems = [...detailItems]
    if (!newItems[itemIndex].blendDetails) {
      newItems[itemIndex].blendDetails = []
    }
    newItems[itemIndex].blendDetails = [
      ...newItems[itemIndex].blendDetails,
      { ...defaultBlendDetail },
    ]
    setDetailItems(newItems)
  }

  const handleRemoveBlendDetail = (itemIndex, blendIndex) => {
    const newItems = [...detailItems]
    newItems[itemIndex].blendDetails = newItems[itemIndex].blendDetails.filter(
      (_, index) => index !== blendIndex
    )
    setDetailItems(newItems)
  }

  const handleBlendDetailChange = (itemIndex, blendIndex, field, value) => {
    const newItems = [...detailItems]
    newItems[itemIndex].blendDetails[blendIndex] = {
      ...newItems[itemIndex].blendDetails[blendIndex],
      [field]: value,
    }
    setDetailItems(newItems)
  }

  const calculateBlendGallons = (percentage, approvalGallons) => {
    const percentageDecimal = parseFloat(percentage) / 100
    return (percentageDecimal * approvalGallons).toFixed(2)
  }

  const calculateTotalPercentage = (blendDetails) => {
    return (blendDetails || []).reduce(
      (sum, detail) => sum + (parseFloat(detail.percentage) || 0),
      0
    )
  }

  const handleSave = () => {
    const status = header.status
    const isApproved = status === "Approved"

    if (!header.date || !header.customer) {
      alert("Please fill in all header fields")
      return
    }

    if (detailItems.length === 0 && isApproved) {
      alert("Please add at least one detail item")
      return
    }

    for (let i = 0; i < detailItems.length; i++) {
      const item = detailItems[i]

      if (!isApproved) {
        continue
      }

      if (!item.contract || item.approvalGallons <= 0) {
        alert(`Please fill in all fields for item ${i + 1}`)
        return
      }

      //  ===== temporaril disable required blend details =====  
      
      // if (!item.blendDetails || item.blendDetails.length === 0) {
      //   alert(`Please add at least one blend detail for item ${i + 1}`)
      //   return
      // }

      // const totalPercentage = calculateTotalPercentage(item.blendDetails)
      // if (Math.abs(totalPercentage - 100) > 0.01) {
      //   alert(`Total percentage for item ${i + 1} must be 100%`)
      //   return
      // }

      for (let j = 0; j < item.blendDetails.length; j++) {
        const blend = item.blendDetails[j]

        if (
          !blend.tank ||
          !blend.blend ||
          !blend.lot ||
          blend.percentage <= 0
        ) {
          alert(
            `Please fill in all fields for blend detail ${j + 1} in item ${
              i + 1
            }`
          )
          return
        }
      }
    }

    handleSaveApproval({
      id: header.id,
      customer: header.customer?.name,
      date: header.date,
      status: header.status,
      approvalLines: detailItems.map((item) => ({
        id: item.id,
        externalSalesContractId: item.contract.id,
        gallons: item.approvalGallons,
        approvalLineInventories: item.blendDetails.map((blend) => ({
          id: blend.id,
          tank: blend.tank,
          blend: blend.blend,
          lot: blend.lot,
          percentage: blend.percentage,
        })),
      })),
    })
  }

  const renderHeader = () => (
    <Box sx={{ p: 2 }}>
      <div className="formRow">
        <TextField
          fullWidth
          type="date"
          label="Date"
          value={header.date}
          onChange={(e) => setHeader({ ...header, date: e.target.value })}
          InputLabelProps={{ shrink: true }}
        />
      </div>
      <div className="formRow">
        <ComboBox
          options={Array.from(
            new Set(contracts?.map((contract) => contract.customerName) || [])
          ).map((name) => ({ name }))}
          label="Customer"
          value={header.customer}
          onChange={(event, newValue) =>
            setHeader({ ...header, customer: newValue })
          }
          getOptionLabel={(option) => option?.name || ""}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          loading={contractsLoading}
        />
      </div>
      <div className="formRow">
        <input
          accept="application/pdf"
          style={{ display: "none" }}
          id="raised-button-file"
          multiple
          type="file"
          onChange={handleFileChange}
          ref={fileInputRef}
        />
        <Box
          sx={{
            border: "1px solid",
            borderColor: "divider",
            borderRadius: 1,
            p: 2,
            mb: 2,
          }}
        >
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Attached Files
          </Typography>
          {uploadedFiles.map((file, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 1,
                "&:last-child": {
                  mb: 0,
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flex: 1,
                  mr: 2,
                  minWidth: 0,
                }}
              >
                <AttachFileIcon
                  sx={{
                    fontSize: 20,
                    color: "primary.main",
                    mr: 1,
                    flexShrink: 0,
                  }}
                />
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    /* Handle file preview */
                  }}
                  sx={{
                    textAlign: "left",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "100%",
                  }}
                  title={file.name}
                >
                  {truncateFileName(file.name)}
                </Link>
              </Box>
              <Typography
                variant="caption"
                sx={{
                  color: "text.secondary",
                  mr: 2,
                  flexShrink: 0,
                }}
              >
                {formatFileSize(file.size)}
              </Typography>
              <IconButton
                size="small"
                onClick={() => handleRemoveFile(index)}
                sx={{
                  color: "error.main",
                  flexShrink: 0,
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          ))}
          <Divider sx={{ my: 2 }} />
          <label htmlFor="raised-button-file">
            <Button
              variant="outlined"
              component="span"
              fullWidth
              startIcon={<AttachFileIcon />}
              size="small"
            >
              Upload Files
            </Button>
          </label>
        </Box>
      </div>
      <div className="formRow">
        <select
          value={header.status}
          onChange={(e) => setHeader({ ...header, status: e.target.value })}
        >
          <option value="Planned">Planned</option>
          <option value="Approved">Approved</option>
          <option value="Deactivated">Deactivated (delete)</option>
        </select>
      </div>
    </Box>
  )

  const renderDetailItem = (item, index) => {
    const totalPercentage = calculateTotalPercentage(item.blendDetails)
    const isPercentageValid = Math.abs(totalPercentage - 100) < 0.01 // Allow for small floating point differences

    return (
      <Box sx={{ p: 2 }}>
        <div className="formRow">
          <ComboBox
            options={
              contracts?.filter(
                (contract) => contract.customerName === header.customer?.name
              ) || []
            }
            label="Contract"
            value={item.contract}
            onChange={(event, newValue) => {
              const newItems = [...detailItems]
              newItems[index] = { ...item, contract: newValue }
              setDetailItems(newItems)
            }}
            getOptionLabel={(option) => option?.id || ""}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            loading={contractsLoading}
          />
        </div>

        <div className="formRow">
          <TextField
            fullWidth
            type="number"
            label="Approval Gallons"
            value={item.approvalGallons}
            onChange={(e) => {
              const newItems = [...detailItems]
              newItems[index] = {
                ...item,
                approvalGallons: parseFloat(e.target.value) || 0,
              }
              setDetailItems(newItems)
            }}
          />
        </div>

        {/* Blend Details Section */}
        <Box sx={{ mt: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="subtitle2">Blend Details</Typography>
              <Typography
                variant="caption"
                sx={{
                  color: isPercentageValid ? "success.main" : "warning.main",
                  fontWeight: "medium",
                }}
              >
                Total: {totalPercentage.toFixed(2)}%
              </Typography>
            </Stack>
            <Button
              size="small"
              startIcon={<AddIcon />}
              onClick={() => handleAddBlendDetail(index)}
            >
              Add Row
            </Button>
          </Box>

          {/* Blend Details Table */}
          <Box
            sx={{
              border: "1px solid",
              borderColor: "divider",
              borderRadius: 1,
              overflow: "hidden",
            }}
          >
            {/* Header */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(6, 1fr)",
                gap: 1,
                p: 1,
                bgcolor: "grey.100",
                borderBottom: "1px solid",
                borderColor: "divider",
              }}
            >
              <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                Tank
              </Typography>
              <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                Blend
              </Typography>
              <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                Lot
              </Typography>
              <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                %
              </Typography>
              <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                Gallons
              </Typography>
              <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                Actions
              </Typography>
            </Box>

            {/* Rows */}
            {(item.blendDetails || []).map((blend, blendIndex) => (
              <Box
                key={blendIndex}
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 60px",
                  gap: 1,
                  p: 1,
                  borderBottom: "1px solid",
                  borderColor: "divider",
                  "&:last-child": {
                    borderBottom: "none",
                  },
                }}
              >
                <TextField
                  size="small"
                  value={blend.tank}
                  onChange={(e) =>
                    handleBlendDetailChange(
                      index,
                      blendIndex,
                      "tank",
                      e.target.value
                    )
                  }
                />
                <TextField
                  size="small"
                  value={blend.blend}
                  onChange={(e) =>
                    handleBlendDetailChange(
                      index,
                      blendIndex,
                      "blend",
                      e.target.value
                    )
                  }
                />
                <TextField
                  size="small"
                  value={blend.lot}
                  onChange={(e) =>
                    handleBlendDetailChange(
                      index,
                      blendIndex,
                      "lot",
                      e.target.value
                    )
                  }
                />
                <TextField
                  size="small"
                  type="number"
                  value={blend.percentage}
                  onChange={(e) =>
                    handleBlendDetailChange(
                      index,
                      blendIndex,
                      "percentage",
                      parseFloat(e.target.value) || 0
                    )
                  }
                  error={totalPercentage > 100}
                  helperText={totalPercentage > 100 ? "Sum > 100%" : ""}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    "& input[type=number]": {
                      "-moz-appearance": "textfield",
                    },
                  }}
                  InputProps={{
                    endAdornment: "%",
                  }}
                />
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "text.secondary",
                  }}
                >
                  {calculateBlendGallons(
                    blend.percentage,
                    item.approvalGallons
                  )}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <IconButton
                    size="small"
                    onClick={() => handleRemoveBlendDetail(index, blendIndex)}
                    sx={{
                      width: 30,
                      height: 30,
                      "&:hover": {
                        backgroundColor: "error.lighter",
                      },
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            ))}

            {/* Empty State */}
            {(!item.blendDetails || item.blendDetails.length === 0) && (
              <Box sx={{ p: 2, textAlign: "center" }}>
                <Typography variant="body2" color="text.secondary">
                  No blend details added yet
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "80vh",
        width: "80vw",
        maxHeight: "900px",
        maxWidth: "1000px",
        minHeight: "600px",
        minWidth: "800px",
        bgcolor: "background.paper",
      }}
    >
      {/* Left side - Navigation */}
      <Paper
        sx={{
          width: "40%",
          overflow: "auto",
          borderRight: 1,
          borderColor: "divider",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <List sx={{ flex: 1, overflow: "auto" }}>
          <ListItemButton
            selected={selectedItemIndex === 0}
            onClick={() => setSelectedItemIndex(0)}
          >
            <ListItemText
              primary="Header Information"
              secondary="Date, Customer, Files"
            />
          </ListItemButton>
          <Divider />
          {detailItems.map((item, index) => (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton
                  edge="end"
                  onClick={() => handleDeleteDetailItem(index)}
                >
                  <DeleteIcon />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton
                selected={selectedItemIndex === index + 1}
                onClick={() => setSelectedItemIndex(index + 1)}
              >
                <ListItemText
                  primary={`Item ${index + 1}`}
                  secondary={`${item.contract?.id || "No Contract"} - ${
                    item.approvalGallons
                  } gal`}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <Box sx={{ p: 1 }}>
          <Button
            fullWidth
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAddDetailItem}
            sx={{ mt: 1 }}
          >
            Add Item
          </Button>
        </Box>
      </Paper>

      {/* Right side - Details */}
      <Box
        sx={{
          width: "60%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            flex: 1,
            overflow: "auto",
            p: 3,
          }}
        >
          {selectedItemIndex === 0
            ? renderHeader()
            : renderDetailItem(
                detailItems[selectedItemIndex - 1],
                selectedItemIndex - 1
              )}
        </Box>

        {/* Action buttons */}
        <Box
          sx={{
            p: 2,
            borderTop: 1,
            borderColor: "divider",
            bgcolor: "background.paper",
          }}
        >
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              disabled={disabled}
              variant="contained"
              color="primary"
              onClick={handleSave}
            >
              SAVE
            </Button>
            <Button variant="outlined" onClick={close}>
              CANCEL
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  )
}

export default CreateApproval
