import { Box, IconButton, Tooltip } from "@mui/material"
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarExport,
} from "@mui/x-data-grid"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import AttachMoneyIcon from "@mui/icons-material/AttachMoney"

export const CustomToolbar = ({
  columnVisibility,
  onToggleWineDetails,
  onToggleFinancials,
}) => {
  if (!columnVisibility) {
    return null
  }
  const areDetailsVisible =
    columnVisibility.vintage ||
    columnVisibility.variety ||
    columnVisibility.appellation

  const areFinancialsVisible =
    columnVisibility.revenue ||
    columnVisibility.cogs ||
    columnVisibility.grossProfit

  return (
    <GridToolbarContainer>
      <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
        <GridToolbarColumnsButton />
        <GridToolbarExport />
        <Tooltip title={`${areDetailsVisible ? "Hide" : "Show"} Wine Details`}>
          <IconButton
            onClick={onToggleWineDetails}
            size="small"
            color={areDetailsVisible ? "primary" : "default"}
          >
            {areDetailsVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </Tooltip>
        <Tooltip
          title={`${areFinancialsVisible ? "Hide" : "Show"} Financial Details`}
        >
          <IconButton
            onClick={onToggleFinancials}
            size="small"
            color={areFinancialsVisible ? "primary" : "default"}
          >
            <AttachMoneyIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </GridToolbarContainer>
  )
}
