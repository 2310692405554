import { useState, useEffect } from "react"
import {
  Box,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  LinearProgress,
  FormControlLabel,
  Checkbox,
} from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import BlenderIcon from "@mui/icons-material/Blender"
import { Breadcrumbs } from "../../components/Breadcrumbs"
import { CustomToolbar } from "../../components/CustomToolbar"
import { BOMBlendModal } from "../../components/BOMBlendModal"

import EditIcon from "@mui/icons-material/Edit"
import Page from "../../components/Page"
import useFetchWithMsal from "../../hooks/useFetchWithMsal"
import "../../styles/Blends.css"

const defaultBlend = {
  name: "",
  protocol: "",
  active: true,
  blendDetails: [],
}

const BLENDS_LIST_URL = "/api/blends"

export const BlendsTable = () => {
  const [selectedBlend, setSelectedBlend] = useState(null)
  const [newBlend, setNewBlend] = useState(null)
  const {
    execute: executeGetBlends,
    isLoading: listLoading,
    data: blendsList,
  } = useFetchWithMsal()
  const { execute: executeGetMD, data: md } = useFetchWithMsal()

  const { execute: executePostBlend, isLoading: saveLoading } =
    useFetchWithMsal()

  useEffect(() => {
    if (!blendsList && !listLoading) {
      executeGetBlends("GET", BLENDS_LIST_URL)
      executeGetMD("GET", "/api/gr/md?resources=locations")
    }
  }, [blendsList, executeGetBlends, listLoading, executeGetMD])

  const saveBlend = async (blend) => {
    const response = blend.id
      ? await executePostBlend("POST", BLENDS_LIST_URL + "/" + blend.id, blend)
      : await executePostBlend("POST", BLENDS_LIST_URL, blend)

    if (!response || response.error) {
      console.error("Error saving blend:", response)
      alert("Error saving blend")
      return
    }
    setNewBlend(null)
    setSelectedBlend(null)
    executeGetBlends("GET", BLENDS_LIST_URL)
  }

  const handleNewBlendSave = async () => {
    const blend = {
      name: newBlend.name,
      protocol: newBlend.protocol,
      blendDetails: newBlend.blendDetails,
      id: newBlend.id || null,
      active: newBlend.active,
    }

    saveBlend(blend)
  }

  const columns = [
    { field: "id", headerName: "ID", width: 90, minWidth: 70 },
    { field: "name", headerName: "Blend", minWidth: 200, flex: 1 },
    {
      field: "active",
      headerName: "Active",
      width: 100,
      minWidth: 80,
      valueGetter: (params) => {
        return params.row.active ? "Yes" : "No"
      },
    },
    { field: "protocol", headerName: "Protocol", width: 130, minWidth: 100 },
    // add button to trigger modal
    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      minWidth: 80,
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            setNewBlend(params.row)
          }}
          size="small"
        >
          <EditIcon />
        </IconButton>
      ),
    },
    {
      field: "actions",
      headerName: "Grapes",
      width: 100,
      minWidth: 80,
      renderCell: (params) => (
        <div>
          <IconButton
            onClick={() => {
              setSelectedBlend(params.row)
            }}
            size="small"
          >
            <BlenderIcon />
          </IconButton>
          <span>{params.row.blendDetails.length || 0}</span>
        </div>
      ),
    },
  ]

  return (
    <Page fullHeight className="Blends">
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Breadcrumbs />
        {(listLoading || saveLoading) && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        <Box
          sx={{
            flex: 1,
            m: 2,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                setNewBlend(defaultBlend)
              }}
              startIcon={<BlenderIcon />}
            >
              NEW BLEND
            </Button>
          </Box>
          <DataGrid
            rows={blendsList || []}
            columns={columns}
            pageSizeOptions={[10, 25, 50]}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 50 },
              },
              sorting: {
                sortModel: [{ field: "id", sort: "desc" }],
              },
            }}
            slots={{
              toolbar: CustomToolbar,
            }}
            disableRowSelectionOnClick
            sx={{
              flex: 1,
              bgcolor: "background.paper",
              borderRadius: 1,
              boxShadow: 2,
              "& .MuiDataGrid-cell": {
                px: 2,
              },
              "& .wine-details-cell": {
                bgcolor: "grey.100",
                fontStyle: "italic",
              },
              "& .vintage-group-header": {
                borderLeft: "2px solid rgba(0, 0, 0, 0.2)",
                borderBottom: "none",
                justifyContent: "center",
              },
              "& .vintage-first-column": {
                borderLeft: "2px solid rgba(0, 0, 0, 0.2)",
              },
              "& .MuiDataGrid-columnHeader.vintage-first-column": {
                borderLeft: "2px solid rgba(0, 0, 0, 0.2)",
              },
            }}
          />
          {selectedBlend && (
            <BOMBlendModal
              md={md}
              open={!!selectedBlend}
              onClose={() => {
                setSelectedBlend(null)
              }}
              blendName={selectedBlend.name}
              blendDetails={selectedBlend.blendDetails}
              vintageGallons={0}
              onSave={(components) => {
                saveBlend({
                  ...selectedBlend,
                  blendDetails: components,
                })
              }}
            />
          )}
          {!!newBlend && (
            <Dialog
              fullWidth
              maxWidth="md"
              open={!!newBlend}
              onClose={() => setNewBlend(null)}
            >
              <DialogTitle>New Blend</DialogTitle>
              <DialogContent>
                <Box
                  sx={{
                    pt: 2,
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <TextField
                    label="Blend Name"
                    value={newBlend.name}
                    onChange={(e) =>
                      setNewBlend({ ...newBlend, name: e.target.value })
                    }
                    fullWidth
                  />
                  <TextField
                    label="Protocol"
                    value={newBlend.protocol}
                    onChange={(e) =>
                      setNewBlend({ ...newBlend, protocol: e.target.value })
                    }
                    fullWidth
                  />
                  {newBlend.id && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!newBlend.active}
                          onChange={(e) =>
                            setNewBlend({
                              ...newBlend,
                              active: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Active"
                    />
                  )}
                </Box>
              </DialogContent>

              <DialogActions>
                <Button
                  onClick={() => {
                    setNewBlend(null)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    handleNewBlendSave()
                  }}
                  variant="contained"
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Box>
      </Box>
    </Page>
  )
}

export default BlendsTable
