import { useState, useMemo } from "react"
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { TextField } from "@mui/material"
import { Button } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/Delete"
const defaultComponent = {
  parentProgramCode: "",
  locationCode: "",
  percentage: 0,
}

export const BOMBlendModal = ({
  open,
  onClose,
  blendName,
  blendDetails: initialComponents,
  md,
  onSave,
}) => {
  const [components, setComponents] = useState(
    initialComponents
      ? initialComponents.map((component) => ({
          parentProgramCode: component.parentProgramCode,
          locationCode: component.locationCode,
          percentage: component.percentage,
        }))
      : []
  )

  const hasUpdated = useMemo(() => {
    return (
      initialComponents.some(
        (component, index) =>
          component.parentProgramCode !== components[index].parentProgramCode ||
          component.locationCode !== components[index].locationCode ||
          component.percentage !== components[index].percentage
      ) || components.length !== initialComponents.length
    )
  }, [initialComponents, components])

  const errorStatement = useMemo(() => {
    if (components.length === 0) return null

    const totalPercentage = components.reduce(
      (acc, component) => acc + component.percentage,
      0
    )

    if (totalPercentage !== 100)
      return `Total percentage must be 100 (${
        totalPercentage?.toFixed(2) || 0
      }%)`

    if (
      components.some(
        (component) =>
          component.parentProgramCode === "" ||
          component.parentProgramCode.length < 5
      )
    )
      return "All parentProgramCodes must be filled and have at least 5 characters"

    return null
  }, [components])

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth fullScreen>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Components for {blendName}</Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
        <>
          {hasUpdated && (
            <div
              style={{
                display: "flex",
                gap: "10px",
              }}
            >
              <Button variant="contained" onClick={onClose} color="error">
                DISCARD CHANGES
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  onSave(components)
                }}
                disabled={!hasUpdated || !!errorStatement}
              >
                SAVE CHANGES
              </Button>
            </div>
          )}
          {errorStatement && (
            <div style={{ color: "red" }}>{errorStatement}</div>
          )}
        </>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: "100%" }}>
          <table
            className="BOMBlendTable"
            style={{
              width: "100%",
              borderCollapse: "collapse",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    textAlign: "left",
                    padding: "3px",
                  }}
                >
                  Program Code (should not include vintage)
                </th>
                <th
                  style={{
                    textAlign: "left",
                    padding: "3px",
                  }}
                >
                  Location
                </th>
                <th
                  style={{
                    textAlign: "left",
                    padding: "3px",
                  }}
                >
                  Percentage
                </th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
              {components.map((component, index) => (
                <tr key={index}>
                  <td style={{ padding: "3px" }}>
                    <div>
                      <TextField
                        size="small"
                        sx={{ width: "300px" }}
                        value={component.parentProgramCode}
                        onChange={(e) => {
                          const newComponents = [...components]
                          newComponents[index].parentProgramCode =
                            e.target.value
                          setComponents(newComponents)
                        }}
                      />
                      <div
                        style={{
                          fontSize: "0.8em",
                          color: "#666",
                          marginTop: "4px",
                        }}
                      >
                        Varietal: {component.parentProgramCode.slice(0, 3)},
                        Appellation: {component.parentProgramCode.slice(3, 5)},
                        Mod: {component.parentProgramCode.slice(5)}
                      </div>
                    </div>
                  </td>
                  <td style={{ padding: "3px" }}>
                    <select
                      style={{ width: "100%", padding: "3px" }}
                      value={component.locationCode || ""}
                      onChange={(e) => {
                        const newComponents = [...components]
                        newComponents[index].locationCode = e.target.value
                        setComponents(newComponents)
                      }}
                    >
                      <option value="">Select Location</option>
                      {md?.locations?.map((location) => (
                        <option key={location.code} value={location.code}>
                          {location.name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td style={{ padding: "3px" }}>
                    <TextField
                      sx={{ width: "100px" }}
                      size="small"
                      value={component.percentage || ""}
                      onChange={(e) => {
                        const newComponents = [...components]
                        const value = e.target.value
                        if (value === "") {
                          newComponents[index].percentage = 0
                        } else {
                          const percentage = parseFloat(value)
                          if (isNaN(percentage)) {
                            newComponents[index].percentage = 0
                          } else {
                            newComponents[index].percentage = percentage
                          }
                        }
                        setComponents(newComponents)
                      }}
                    />
                  </td>
                  <td>
                    <IconButton
                      onClick={() => {
                        const newComponents = [...components]
                        newComponents.splice(index, 1)
                        setComponents(newComponents)
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div>
            <IconButton
              onClick={() => {
                setComponents([
                  ...components,
                  {
                    ...defaultComponent,
                    locationCode: md?.locations?.[0]?.code || "RE",
                  },
                ])
              }}
            >
              <AddIcon />
            </IconButton>
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
