import { useState, useEffect } from "react"
import useFetchWithMsal from "../../hooks/useFetchWithMsal"
import LinearProgress from "@mui/material/LinearProgress"
import { Box, Button, Dialog, Stack, IconButton } from "@mui/material"
import "../../styles/B2B.css"
import { DataGrid } from "@mui/x-data-grid"
import { Breadcrumbs } from "../../components/Breadcrumbs"
import FileOpenIcon from "@mui/icons-material/FileOpen"
import EditIcon from "@mui/icons-material/Edit"
import CreateApproval from "../../components/CreateApproval"
import Page from "../../components/Page"

const APPROVALS_LIST_URL = "/api/demand/approvals"

export const B2BApprovalsTable = () => {
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedApproval, setSelectedApproval] = useState(null)
  const [newApproval, setNewApproval] = useState(null)

  const {
    execute: executeGetApprovals,
    isLoading: listLoading,
    data: approvalsList,
  } = useFetchWithMsal()

  const { execute: executePostApproval, isLoading: saveLoading } =
    useFetchWithMsal()

  const [columnVisibility, setColumnVisibility] = useState({
    id: true,
    date: true,
    customer: true,
    contract: true,
    contractBlend: true,
    contractGallons: true,
    approvalGallons: true,
  })

  useEffect(() => {
    if (!approvalsList && !listLoading) {
      executeGetApprovals("GET", APPROVALS_LIST_URL)
    }
  }, [approvalsList, executeGetApprovals, listLoading])

  const handleFileOpen = (row) => {
    setSelectedFile(row)
  }

  const handleEdit = (row) => {
    setSelectedApproval(row)
  }

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 60,
    },
    {
      field: "date",
      headerName: "Date",
      width: 130,
      valueFormatter: (params) => {
        if (!params.value) return ""
        return new Date(params.value).toLocaleDateString()
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
    },
    {
      field: "customer",
      headerName: "Customer",
      width: 180,
      flex: 1,
    },
    {
      field: "contract",
      headerName: "Contract",
      width: 130,
    },
    {
      field: "contractBlend",
      headerName: "Contract Blend",
      width: 140,
    },
    {
      field: "contractGallons",
      headerName: "Contract Gallons",
      width: 150,
      type: "number",
      valueFormatter: (params) => params.value?.toLocaleString() || "",
    },
    {
      field: "approvedGallons",
      headerName: "Approval Gallons",
      width: 150,
      type: "number",
      valueFormatter: (params) => params.value?.toLocaleString() || "",
    },
    {
      field: "user",
      headerName: "Entered By",
      width: 150,
      renderCell: (params) => params.row?.user || "",
    },
    {
      field: "file",
      headerName: "File",
      width: 100,
      renderCell: (params) => (
        <IconButton
          size="small"
          onClick={() => handleFileOpen(params.row)}
          color="primary"
        >
          <FileOpenIcon />
        </IconButton>
      ),
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      renderCell: (params) => (
        <IconButton
          size="small"
          onClick={() => {
            handleEdit(params.row?.approval)
          }}
          color="primary"
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ]

  // need to turn [{approval, approvalLines: []}] into [approvalLine]
  const approvalsListFlat =
    (approvalsList?.length > 0 &&
      approvalsList
        ?.map((approval) =>
          approval.approvalLines.map((line) => ({
            id: approval.id,
            date: approval.date,
            customer: approval.customer,
            lineId: line.id,
            approvedGallons: line.gallons,
            approval,
            contract: line.externalSalesContract?.id,
            contractGallons: line.externalSalesContract?.contractGallons,
            contractBlend: line.externalSalesContract?.blend,
            user: approval.user?.name,
            status: approval.status,
          }))
        )
        .flat()) ||
    []

  return (
    <Page fullHeight className="B2B">
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Breadcrumbs />
        {(listLoading || saveLoading) && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            minHeight: 0,
          }}
        >
          <Box sx={{ py: 1 }}>
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              <Button
                size="small"
                variant="outlined"
                onClick={() => setNewApproval({})}
              >
                NEW APPROVAL
              </Button>
            </Stack>
          </Box>
          <Box
            sx={{
              flex: 1,
              minHeight: 0,
              display: "flex",
            }}
            className="body"
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                flex: 1,
                maxHeight: "100%",
              }}
              className="gridContainer"
            >
              <DataGrid
                rows={approvalsListFlat}
                columns={columns}
                disableRowSelectionOnClick
                rowBuffer={10}
                getRowId={(row) => row.lineId}
                rowHeight={40}
                hideFooterPagination
                disablePagination
                columnVisibilityModel={columnVisibility}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibility(newModel)
                }
                sx={{
                  flex: 1,
                  bgcolor: "background.paper",
                  borderRadius: 1,
                  boxShadow: 2,
                  "& .MuiDataGrid-cell": {
                    px: 2,
                  },
                }}
                sortModel={[{ field: "id", sort: "desc" }]}
              />
            </Box>
          </Box>
        </Box>
        <Dialog
          open={!!selectedFile}
          onClose={() => setSelectedFile(null)}
          maxWidth="md"
          fullWidth
        >
          <Box sx={{ p: 2 }}>
            {selectedFile?.fileContent ? (
              <iframe
                src={selectedFile.fileContent}
                style={{ width: "100%", height: "80vh" }}
                title="File Preview"
              />
            ) : (
              <div>No file content available</div>
            )}
          </Box>
        </Dialog>
        {(!!newApproval || !!selectedApproval) && (
          <Dialog
            open={!!newApproval || !!selectedApproval}
            onClose={() => {
              setNewApproval(null)
              setSelectedApproval(null)
            }}
            maxWidth={false}
            fullWidth={false}
            PaperProps={{
              sx: {
                borderRadius: 2,
                bgcolor: "background.paper",
              },
            }}
          >
            <Box sx={{ p: 0 }}>
              <CreateApproval
                disabled={saveLoading}
                approval={selectedApproval}
                handleSaveApproval={async (approvalData) => {
                  try {
                    const response = await executePostApproval(
                      "POST",
                      "/api/demand/approvals",
                      approvalData
                    )

                    if (!response || response.error) {
                      console.error("Error saving approval:", response)
                      alert("Error saving approval")
                      return
                    }

                    if (response) {
                      setNewApproval(null)
                      setSelectedApproval(null)
                      executeGetApprovals("GET", APPROVALS_LIST_URL)
                    }
                  } catch (error) {
                    console.error("Error saving approval:", error)
                    alert("Error saving approval")
                  }
                }}
                close={() => {
                  setNewApproval(null)
                  setSelectedApproval(null)
                }}
              />
            </Box>
          </Dialog>
        )}
      </Box>
    </Page>
  )
}

export default B2BApprovalsTable
