import { Box } from "@mui/material"
import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from "@mui/material"
import { Link as RouterLink, useLocation } from "react-router-dom"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"

const getBreadcrumbData = (pathname) => {
  const paths = pathname.split("/").filter(Boolean)
  const breadcrumbs = [{ text: "Home", path: "/" }]

  let currentPath = ""
  paths.forEach((path) => {
    currentPath += `/${path}`
    const formattedText = path
      .split(/(?=[A-Z])/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join("")

    breadcrumbs.push({
      text: formattedText,
      path: currentPath,
    })
  })

  return breadcrumbs
}

export const Breadcrumbs = () => {
  const location = useLocation()
  const breadcrumbs = getBreadcrumbData(location.pathname)

  if (breadcrumbs.length <= 1) return null

  return (
    <Box
      sx={{
        px: 3,
        py: 1,
        bgcolor: "background.paper",
        borderBottom: 1,
        borderColor: "divider",
      }}
    >
      <MuiBreadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        {breadcrumbs.map((crumb, index) => {
          const isLast = index === breadcrumbs.length - 1

          return isLast ? (
            <Typography
              key={crumb.path}
              color="text.primary"
              variant="subtitle2"
            >
              {crumb.text}
            </Typography>
          ) : (
            <Link
              key={crumb.path}
              component={RouterLink}
              to={crumb.path}
              color="inherit"
              variant="subtitle2"
              sx={{
                textDecoration: "none",
                "&:hover": { textDecoration: "underline" },
              }}
            >
              {crumb.text}
            </Link>
          )
        })}
      </MuiBreadcrumbs>
    </Box>
  )
}
