import Page from "../components/Page"
import {
  Button,
  Stack,
  LinearProgress,
  Autocomplete,
  TextField,
  Tooltip,
  Dialog,
  Box,
} from "@mui/material"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import SaveIcon from "@mui/icons-material/Save"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess"
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore"
import { useNavigate, useSearchParams } from "react-router-dom"
import useFetchWithMsal from "../hooks/useFetchWithMsal"
import { useCallback, useEffect, useMemo, useState } from "react"
import DatesSlider from "../components/DemandSupplyDateSlider"

import "../styles/DemandSupply.css"

const NineLiters_TOGALLONS = 2.37755

export const vintageColors = {
  NV: { r: 136, g: 159, b: 181, a: 0.5 },
  17: { r: 255, g: 0, b: 0, a: 0.5 },
  18: { r: 255, g: 165, b: 0, a: 0.5 },
  19: { r: 255, g: 255, b: 0, a: 0.5 },
  20: { r: 0, g: 128, b: 0, a: 0.5 },
  21: { r: 0, g: 0, b: 255, a: 0.5 },
  22: { r: 75, g: 0, b: 130, a: 0.5 },
  23: { r: 255, g: 134, b: 52, a: 0.5 },
  24: { r: 209, g: 87, b: 87, a: 0.5 },
  25: { r: 0, g: 85, b: 2, a: 0.5 },
  26: { r: 245, g: 77, b: 77, a: 1 },
  27: { r: 116, g: 93, b: 255, a: 1 },
  28: { r: 255, g: 215, b: 0, a: 0.5 },
}

export const formatNumber = (value, decimals) => {
  if (value === null || value === undefined) {
    return ""
  }

  return value.toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const ifStringConvertToDate = (date) => {
  if (typeof date === "string") {
    const [year, month, day] = date.split("-")
    return new Date(year, month - 1, day)
  }
  return date
}

const groupInventoryByAllocationBlend = (data) => {
  const inventory = data?.inventory

  if (!inventory) {
    return null
  }
  console.log("running groupInventoryByAllocationBlend")

  const grouped = {}
  const x = [...inventory]
  x.forEach((item) => {
    const invRec = { ...item }
    const key =
      invRec.state === "CASED" || invRec.allocationBlend.slice(0, 2) === "NV"
        ? invRec.allocationBlend
        : invRec.allocationBlend.slice(2)
    const altKey =
      invRec.state !== "CASED" && invRec.allocationBlend.slice(0, 2) !== "NV"
        ? invRec.allocationBlend
        : null

    if (!grouped[key]) {
      grouped[key] = []
    }
    grouped[key].push({ ...invRec, key })

    if (altKey) {
      if (!grouped[altKey]) {
        grouped[altKey] = []
      }
      grouped[altKey].push({ ...invRec, key: altKey })
    }
  })
  return grouped
}

const getInventoryQuantity = (inventory, sku, vintage, level) => {
  const q = inventory
    .filter((i) => {
      if (level === "blend") {
        return i.state === "TANK"
      } else {
        return i.sku === sku && i.vintage === vintage
      }
    })
    .reduce((acc, item) => acc + item.quantity, 0)

  return q === 0 ? "" : formatNumber(q, 0)
}

const StackedBar = ({ segments, maxValue, totalHeight, outerWidth }) => {
  return (
    <div
      className="stacked-bar"
      style={{
        height: `${totalHeight}px`,
        width: outerWidth,
      }}
    >
      {segments.map((segment, index) => (
        <div
          key={index}
          className="segment"
          style={{
            height: maxValue ? (segment.value / maxValue) * totalHeight : 0,
            backgroundColor: segment.color,
          }}
        ></div>
      ))}
    </div>
  )
}

const BOMPeriodEdit = ({
  bomPeriod,
  single,
  removeBOMPeriod,
  updateBOMPeriodDetails,
  locations,
  validationIssues,
}) => {
  return (
    <div className="bomPeriod">
      <div className="left">
        {validationIssues &&
          validationIssues.filter((vi) => vi.detail === undefined).length >
            0 && (
            <div className="validationIssues">
              {validationIssues
                .filter((vi) => vi.detail === undefined)
                .map((issue, index) => (
                  <div key={`xdij${index}`}>{issue.issue}</div>
                ))}
            </div>
          )}
        {!single && (
          <div className="dates">
            <div className="name">Period: {bomPeriod.periodName}</div>
            <div>
              START:{" "}
              {ifStringConvertToDate(bomPeriod.startDate).toLocaleDateString()}
            </div>
            <div>
              END:{" "}
              {ifStringConvertToDate(bomPeriod.endDate).toLocaleDateString()}
            </div>
          </div>
        )}
        <div className="detailsCont">
          <div className="top">
            <div>PROGRAMS</div>{" "}
            <button
              onClick={() => {
                const existing = bomPeriod.bomDetails || []
                updateBOMPeriodDetails([
                  ...existing,
                  {
                    programCode: null,
                    locationCode: null,
                    percentage: 1,
                  },
                ])
              }}
            >
              +
            </button>{" "}
          </div>
          <div className="details">
            {bomPeriod.bomDetails?.map((detail, index) => (
              <div key={`xdij${index}`}>
                {validationIssues &&
                  validationIssues.filter((vi) => vi.detail === index).length >
                    0 && (
                    <div className="validationIssues">
                      {validationIssues
                        .filter((vi) => vi.detail === index)
                        .map((issue, index) => (
                          <div key={`xdij${index}`}>{issue.issue}</div>
                        ))}
                    </div>
                  )}
                <div className="detail">
                  <div className="programCode">
                    <label htmlFor="program">Program</label>
                    <input
                      type="text"
                      value={detail.programCode || ""}
                      onChange={(e) =>
                        updateBOMPeriodDetails(
                          bomPeriod.bomDetails.map((d, i) =>
                            i === index
                              ? {
                                  ...d,
                                  programCode: e.target.value,
                                }
                              : d
                          )
                        )
                      }
                    />
                  </div>
                  <div className="locationCode">
                    <label htmlFor="location">Location</label>
                    <select
                      style={{ width: "100px" }}
                      size="small"
                      id="location"
                      value={detail.locationCode || ""}
                      label="Location"
                      onChange={(e) =>
                        updateBOMPeriodDetails(
                          bomPeriod.bomDetails.map((d, i) =>
                            i === index
                              ? {
                                  ...d,
                                  locationCode: e.target.value,
                                }
                              : d
                          )
                        )
                      }
                    >
                      <option value={""}></option>
                      {locations?.map((location) => (
                        <option key={location.code} value={location.code}>
                          {location.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="percentage">
                    <label htmlFor="percentage">Percentage</label>
                    <input
                      type="number"
                      value={detail.percentage}
                      onChange={(e) =>
                        updateBOMPeriodDetails(
                          bomPeriod.bomDetails.map((d, i) =>
                            i === index
                              ? {
                                  ...d,
                                  percentage: e.target.value,
                                }
                              : d
                          )
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {!single && (
        <div>
          <Button
            size="small"
            variant="outlined"
            color="error"
            onClick={() => removeBOMPeriod()}
          >
            REMOVE PERIOD
          </Button>
        </div>
      )}
    </div>
  )
}

const EditBOM = ({
  bom,
  updateBOM,
  setBOMEditState,
  reset,
  bomType,
  itemLabel,
  addBOMPeriod,
  saveBOM,
  validationIssues,
}) => {
  const { execute: executeGetData, data } = useFetchWithMsal()

  useEffect(() => {
    if (!data) {
      executeGetData("GET", "/api/gr/md?resources=locations,blends")
    }
  }, [executeGetData, data])

  const removeBOMPeriod = (index) => {
    let newBOMPeriods = [...bom.bomPeriods]
    newBOMPeriods.splice(index, 1)
    updateBOM({
      ...bom,
      bomPeriods: newBOMPeriods,
    })
  }

  // a bom period is an object with periodName, startDate, and endDate
  // it also has a property named details which is an array of objects {programCode: '', locationCode: '', percentage: 34}
  const updateBOMPeriodDetails = (index, newValues) => {
    let newBOMPeriods = [...bom.bomPeriods]
    newBOMPeriods[index].bomDetails = newValues
    updateBOM({
      ...bom,
      bomPeriods: newBOMPeriods,
    })
  }

  return (
    <div className="EditBOM">
      <div>Edit BOM: {itemLabel}</div>
      <div>BOM Type: {bomType}</div>
      <div>
        Blend:{" "}
        <select
          onChange={(e) => {
            const blendId = e.target.value
            updateBOM({
              ...bom,
              blendId: blendId || null,
            })
          }}
          value={bom?.blendId || ""}
        >
          <option value={""}></option>

          {data?.blends?.map((blend) => (
            <option key={`osdoijr${blend.id}`} value={blend.id}>
              {blend.name}
            </option>
          ))}
        </select>
      </div>
      <div className="bomPeriods">
        {bomType === "Single" ? (
          <BOMPeriodEdit
            locations={data?.locations}
            bomPeriod={bom && bom.bomPeriods.length > 0 && bom.bomPeriods[0]}
            update={updateBOM}
            single
            removeBOMPeriod={() => removeBOMPeriod(0)}
            updateBOMPeriodDetails={(newValues) =>
              updateBOMPeriodDetails(0, newValues)
            }
            validationIssues={validationIssues}
          />
        ) : (
          bom?.bomPeriods.map((bomPeriod, index) => (
            <div key={`xdij${index}`}>
              <BOMPeriodEdit
                locations={data?.locations}
                bomPeriod={bomPeriod}
                update={updateBOM}
                removeBOMPeriod={() => removeBOMPeriod(index)}
                updateBOMPeriodDetails={(newValues) =>
                  updateBOMPeriodDetails(index, newValues)
                }
                validationIssues={validationIssues.filter(
                  (issue) => issue.period === index
                )}
              />
            </div>
          ))
        )}
        {bomType !== "Single" && (
          <div
            style={{
              marginTop: "10px",
            }}
          >
            <Button
              size="small"
              onClick={() => {
                addBOMPeriod()
              }}
            >
              Add Period
            </Button>
          </div>
        )}
      </div>
      <div className="buttons">
        <Button
          color="primary"
          variant="contained"
          size="small"
          disabled={validationIssues.length > 0 && !bom?.blendId}
          onClick={() => {
            saveBOM()
          }}
        >
          SAVE
        </Button>
        <Button
          size="small"
          onClick={() => {
            reset()
          }}
        >
          CANCEL EDIT
        </Button>
        {bomType === "Date Bound" && (
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={() => {
              setBOMEditState({
                periodEdit: true,
                itemsEdit: false,
              })
            }}
          >
            EDIT DATE RANGES
          </Button>
        )}
      </div>
    </div>
  )
}

const BOMSection = ({
  itemLabel,
  periodsObj,
  bom,
  updateBOM,
  setBOMEditState,
  resetBOM,
  bomEditState,
  bomDemandId,
  bomType,
  saveBOMServer,
}) => {
  const validationIssues = useMemo(() => {
    const issues = []
    bom?.bomPeriods.forEach((bomPeriod, index) => {
      const percentageSum = bomPeriod.bomDetails?.reduce(
        (acc, detail) => acc + Number(detail.percentage || 0),
        0
      )

      if (percentageSum !== 100) {
        issues.push({
          period: index,
          issue: "Percentage total must be 100",
        })
      }

      bomPeriod.bomDetails?.forEach((detail, detailIndex) => {
        if (!detail.programCode) {
          issues.push({
            period: index,
            detail: detailIndex,
            issue: "Program Code is required",
          })
        }
        if (!detail.locationCode) {
          issues.push({
            period: index,
            detail: detailIndex,
            issue: "Location Code is required",
          })
        }
        if (detail.percentage === null || detail.percentage === "") {
          issues.push({
            period: index,
            detail: detailIndex,
            issue: "Percentage is required",
          })
        }
      })
    })
    return issues
  }, [bom])

  const saveBOM = () => {
    saveBOMServer()
  }

  const newBOM = () => {
    setBOMEditState({
      periodEdit: bomType !== "Single",
      itemsEdit: true,
    })

    let startDate = null
    let endDate = null
    if (periodsObj && Object.keys(periodsObj).length > 0) {
      const firstDateOfUnsupplied = Object.keys(periodsObj).find(
        (key) => periodsObj[key].totalUnsupplied > 0
      )

      startDate = new Date(
        Number(firstDateOfUnsupplied.split("/")[1]),
        Number(firstDateOfUnsupplied.split("/")[0]) - 1,
        1
      )
      let startDateCopy = new Date(startDate)
      startDateCopy.setMonth(startDateCopy.getMonth() + 1)
      endDate = startDateCopy
    } else {
      startDate = new Date()
      let startDateCopy = new Date(startDate)
      startDateCopy.setMonth(startDateCopy.getMonth() + 1)
      endDate = startDateCopy
    }

    updateBOM({
      demandId: bomDemandId,
      versionNumber: 1,
      type: bomType,
      active: true,
      bomPeriods: [
        {
          periodName: "25", // should change eventually
          startDate: bomType === "Single" ? null : startDate,
          endDate: bomType === "Single" ? null : endDate,
        },
      ],
    })
  }

  const addBOMPeriod = () => {
    if (bomType === "Single") {
      return
    }

    let startDate = new Date()
    let endDate = new Date()
    let newBOMPeriods = []
    let periodName = "New BOM Period"
    if (bom?.bomPeriods.length > 0) {
      startDate = new Date(
        ifStringConvertToDate(bom.bomPeriods[bom.bomPeriods.length - 1].endDate)
      )
      endDate = new Date(startDate)
      endDate.setMonth(endDate.getMonth() + 1)
      newBOMPeriods = [...bom.bomPeriods]

      let periodNameNum = Number(
        newBOMPeriods[newBOMPeriods.length - 1].periodName
      )
      periodNameNum++
      periodName = periodNameNum.toString()
    } else {
      startDate = new Date(
        Number(Object.keys(periodsObj)[0].split("/")[1]),
        Number(Object.keys(periodsObj)[0].split("/")[0]) - 1,
        1
      )
      endDate = new Date(startDate)
      endDate.setMonth(endDate.getMonth() + 1)
    }

    newBOMPeriods.push({
      periodName: periodName,
      startDate: startDate,
      endDate: endDate,
    })
    updateBOM({
      ...bom,
      bomPeriods: newBOMPeriods,
    })
  }

  return (
    <div className="bomSection">
      {bom && <div>Blend: {bom.blend?.name || "None"} </div>}
      {!bomEditState.itemsEdit && !bomEditState.periodEdit && !bom && (
        <Button
          startIcon={<AddCircleIcon />}
          size="small"
          variant="outlined"
          onClick={(e) => {
            e.stopPropagation()
            if (!bom) {
              newBOM()
            }
          }}
        >
          BOM
        </Button>
      )}
      <div className="buttons">
        {bom && (
          <Button
            startIcon={<ModeEditIcon />}
            size="small"
            variant="outlined"
            onClick={(e) => {
              e.stopPropagation()
              setBOMEditState({
                periodEdit: false,
                itemsEdit: true,
              })
            }}
          >
            EDIT
          </Button>
        )}
        {bomEditState.periodEdit && bomType === "Date Bound" && (
          <Button
            startIcon={<SaveIcon />}
            size="small"
            variant="outlined"
            onClick={(e) => {
              e.stopPropagation()
              saveBOM()
            }}
            disabled={validationIssues.length > 0 && !bom?.blendId}
          >
            SAVE
          </Button>
        )}
      </div>
      {bomEditState.periodEdit && bomType === "Date Bound" && (
        <div className="buttons">
          <Button
            variant="contained"
            size="small"
            color="success"
            onClick={(e) => {
              e.stopPropagation()
              addBOMPeriod()
            }}
          >
            Add Period
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={(e) => {
              e.stopPropagation()
              resetBOM()
            }}
          >
            Cancel
          </Button>
        </div>
      )}
      {bomEditState.itemsEdit && (
        <Dialog
          open={bomEditState.itemsEdit}
          onClose={() => resetBOM()}
          maxWidth="md"
          fullWidth
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <Box style={{ padding: "10px" }}>
            <EditBOM
              validationIssues={validationIssues}
              itemLabel={itemLabel}
              bomType={bomType}
              bom={bom}
              updateBOM={updateBOM}
              setBOMEditState={setBOMEditState}
              reset={resetBOM}
              addBOMPeriod={addBOMPeriod}
              saveBOM={saveBOM}
            />
          </Box>
        </Dialog>
      )}
    </div>
  )
}

const Row = ({
  level,
  demandSupply,
  inventory,
  type,
  bomType,
  allocationBlend,
  toggleExpanded,
  bom,
  updateBOM,
  bomDemandId,
  bomEditState,
  setBOMEditState,
  resetBOM,
  vintageTransitions,
  saveBOM,
  bomPeriodDemand,
}) => {
  const [periodsObj, maxDemand] = useMemo(() => {
    if (!demandSupply || demandSupply.periods.length === 0) {
      return [{}, 0]
    }

    let maxDemand = 0
    const periods = {}
    const currentMonth = new Date().getMonth()
    const currentYear = new Date().getFullYear()
    const numMonths = 84
    for (let i = 0; i < numMonths; i++) {
      const month = (currentMonth + i) % 12
      const year = currentYear + Math.floor((currentMonth + i) / 12)
      periods[`${month + 1}/${year}`] = {
        totalDemand: 0,
        totalSupplied: 0,
        totalUnsupplied: 0,
        supplySources: [],
        yearMonth: `${month + 1}/${year - 2000}`,
      }
    }

    demandSupply.periods.forEach((period) => {
      const [year, month] = period.period.split("-").map(Number)

      if (!periods[`${month}/${year}`]) {
        return [null, 0]
      }
      periods[`${month}/${year}`] = {
        ...periods[`${month}/${year}`],
        ...period,
      }
      maxDemand = Math.max(maxDemand, period.totalDemand)
    })

    return [periods, maxDemand]
  }, [demandSupply])

  const updateBOMPeriodDate = (index, dateField, newValue) => {
    let newBOMPeriods = [...bom.bomPeriods]
    newBOMPeriods[index][dateField] = newValue
    updateBOM({
      ...bom,
      bomPeriods: newBOMPeriods,
    })
  }

  return (
    <div className={`Row ${level}`}>
      <div
        className="left"
        onClick={level === "blend" ? () => toggleExpanded() : null}
      >
        <div className="titleCont">
          {level === "blend" ? (
            <>
              <div className="title">
                <span>
                  <span className="type">{type}</span>
                  <span className="blend">{allocationBlend}</span>
                </span>
              </div>
              <BOMSection
                bom={bom}
                updateBOM={updateBOM}
                setBOMEditState={setBOMEditState}
                resetBOM={resetBOM}
                periodsObj={periodsObj}
                bomEditState={bomEditState}
                bomDemandId={bomDemandId}
                bomType={bomType}
                itemLabel={allocationBlend}
                saveBOMServer={saveBOM}
              />
            </>
          ) : (
            <div className="title">{demandSupply.sku}</div>
          )}
        </div>
        {Object.keys(inventory).length === 0 &&
        (!demandSupply || demandSupply.periods.length === 0) ? (
          <div className="nonelabel">No Inventory</div>
        ) : (
          <div className="vintages">
            {bomType === "Date Bound" &&
              Object.keys(inventory).map((vintage, index) => (
                <div
                  className="vintageCol actual"
                  key={`xdi${index}`}
                  style={{
                    backgroundColor: vintageColors[vintage]
                      ? `rgba(${vintageColors[vintage].r}, ${vintageColors[vintage].g}, ${vintageColors[vintage].b}, ${vintageColors[vintage].a})`
                      : "lightgrey",
                  }}
                >
                  {level === "blend" && (
                    <div className="vintage">{vintage}</div>
                  )}
                  <div className="quantity">
                    {getInventoryQuantity(
                      inventory[vintage],
                      demandSupply.sku,
                      vintage,
                      level
                    )}
                  </div>
                </div>
              ))}
            {bomType === "Date Bound" &&
              bomPeriodDemand?.map((bpd, index) => (
                <div
                  className="vintageCol bom"
                  key={`xdi${index}`}
                  style={{
                    backgroundColor: vintageColors[bpd.period]
                      ? `rgba(${vintageColors[bpd.period].r}, ${
                          vintageColors[bpd.period].g
                        }, ${vintageColors[bpd.period].b}, ${
                          vintageColors[bpd.period].a
                        })`
                      : "lightgrey",
                  }}
                >
                  {level === "blend" && (
                    <>
                      <div className="vintage">{bpd.period}</div>
                      <div className="quantity">
                        {bpd.quantity ? formatNumber(bpd.quantity, 0) : 0}
                      </div>
                    </>
                  )}
                </div>
              ))}
            {bomType === "Single" && (
              <div className="SingleBOMBalance">
                <div>
                  <div className="label">Total Demand:</div>
                  <div className="value">
                    {formatNumber(
                      demandSupply.periods.reduce(
                        (acc, p) => acc + p.totalDemand,
                        0
                      ),
                      0
                    )}
                  </div>
                </div>
                <div>
                  <div className="label">Allocated:</div>
                  <div className="value">
                    {formatNumber(
                      Object.values(inventory).reduce((acc, v) => {
                        return acc + v.reduce((acc2, i) => acc2 + i.quantity, 0)
                      }, 0),
                      0
                    )}
                  </div>
                </div>
                <div>
                  <div className="label">Need:</div>
                  <div className="value">
                    {formatNumber(
                      demandSupply.periods.reduce(
                        (acc, p) => acc + p.totalUnsupplied,
                        0
                      ),
                      0
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="right">
        <div className="periods">
          <div className="yAxis">{formatNumber(maxDemand, 0)}</div>
          <div className="bomAndPeriods">
            {(bom || (vintageTransitions && vintageTransitions.length > 0)) &&
              bomType === "Date Bound" && (
                <div className="bomRow">
                  <DatesSlider
                    allowEdit={bomEditState.periodEdit}
                    vintageTransitions={vintageTransitions}
                    bomPeriods={bom?.bomPeriods}
                    sectionWidth={24}
                    dates={Object.keys(periodsObj)}
                    updateBOMPeriodDate={updateBOMPeriodDate}
                  />
                </div>
              )}
            <div className="periodRow">
              {Object.keys(periodsObj).map((period, index) => {
                const p = periodsObj[period]
                return (
                  <div className="period" key={`xdij${index}`}>
                    {level === "blend" && (
                      <div className="date">{p.yearMonth}</div>
                    )}
                    <Tooltip
                      placement="left"
                      title={
                        <>
                          <div>
                            <div className="title">Total Demand</div>
                            <div className="value">
                              {formatNumber(p.totalDemand, 0)}
                            </div>
                          </div>
                          <div>
                            <div className="title">Total Supplied</div>
                            <div className="value">
                              {formatNumber(p.totalSupplied, 0)}
                            </div>
                          </div>
                          <div>
                            <div className="title">Total Unsupplied</div>
                            <div className="value">
                              {formatNumber(p.totalUnsupplied, 0)}
                            </div>
                          </div>
                          <div>
                            <div className="title">Supply Sources</div>
                            {p.supplySources.map((s, index) => (
                              <div key={`xdij${index}`}>
                                <div className="source">
                                  {s.vintage}: {formatNumber(s.fulfilled, 0)}
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      }
                    >
                      <div className="values">
                        <StackedBar
                          segments={[
                            ...p.supplySources.map((s) => ({
                              value: s.fulfilled,
                              color: vintageColors[s.vintage]
                                ? `rgba(${vintageColors[s.vintage].r}, ${
                                    vintageColors[s.vintage].g
                                  }, ${vintageColors[s.vintage].b}, ${
                                    vintageColors[s.vintage].a
                                  })`
                                : "lightgrey",
                            })),
                            {
                              value: p.totalUnsupplied,
                              color: "rgba(0,0,0,0.5)",
                            },
                          ]}
                          maxValue={maxDemand}
                          totalHeight={level === "blend" ? 30 : 20}
                          outerWidth={"70%"}
                        />
                      </div>
                    </Tooltip>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// parameter demands: [{sku: '', allocationBlend: '', type: '', demandItemForecast: {demandItemForecastDetails: [{period: '1/1/2025', quantity: 100}]}}]
// parameter inventory: [{sku: '', allocationBlend: '', vintage: '19', quantity: 50, state: 'CASED'}]
// return [{sku: '', periods: [{period: '1/1/2025', totalDemand: 100, totalSupplied: 50, totalUnsupplied: 50, supplySources: [{fulfilled: 50, vintage: '19'}]}]}]
const convertToSkuDemandAndSupply = (demands, inventory, level) => {
  if (
    !demands ||
    // !inventory ||
    demands?.demandItemForecasts === null ||
    demands?.demandItemForecasts?.length === 0
  ) {
    return [[], []]
  }

  // vintageTransitions should be [{vintage: '20', startDate: '1/1/2025', endDate: '1/1/2026'}]
  // representing when that vintage is being used
  let vintageTransitions = []

  const skuDemandAndSupply = []
  const combinedInv = {}
  inventory?.forEach((item) => {
    const key = `${item.sku}-${item.vintage}`
    if (!combinedInv[key]) {
      combinedInv[key] = { sku: item.sku, vintage: item.vintage, quantity: 0 }
    }
    combinedInv[key].quantity += item.quantity
  })
  const skuVintageInvArr = Object.values(combinedInv)

  const skuInvObj = skuVintageInvArr.reduce((acc, item) => {
    if (!acc[level === "blend" ? level : item.sku]) {
      acc[level === "blend" ? level : item.sku] = []
    }
    acc[level === "blend" ? level : item.sku].push(item)
    return acc
  }, {})
  Object.keys(skuInvObj).forEach((key) => {
    skuInvObj[key].sort((a, b) => a.vintage - b.vintage)
  })

  // now skuInvObj should be {sku: [{sku: '', vintage: '19', quantity: 50}, {sku: '', vintage: '20', quantity: 50}]}

  let runningInventory = skuInvObj || {}
  let vintageTransitionsObj = {}

  demands.forEach((demand) => {
    if (!demand.demandItemForecasts[0]) {
      return
    }
    const sku = level === "blend" ? level : demand.sku
    const demandItemForecastDetails =
      demand.demandItemForecasts[0].demandItemForecastDetails.sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      )
    const periods = demandItemForecastDetails.map((detail) => {
      const period = detail.date
      const totalDemand = detail.quantity
      let totalSupplied = 0
      let totalUnsupplied = 0
      const supplySources = []
      let remainingDemand = totalDemand
      let i = 0
      while (
        remainingDemand > 0 &&
        runningInventory[sku] &&
        i < runningInventory[sku].length
      ) {
        const inv = runningInventory[sku][i]
        const supplied = Math.min(remainingDemand, inv.quantity)
        totalSupplied += supplied
        supplySources.push({ fulfilled: supplied, vintage: inv.vintage })
        remainingDemand -= supplied

        if (!vintageTransitionsObj[inv.vintage] && supplied > 0) {
          vintageTransitionsObj[inv.vintage] = {
            startDate: period,
            endDate: null,
          }
        }
        vintageTransitionsObj[inv.vintage].endDate = period

        runningInventory[sku][i].quantity -= supplied

        if (runningInventory[sku][i].quantity === 0) {
          runningInventory[sku].splice(i, 1)
        } else {
          i++
        }
      }

      totalUnsupplied = totalDemand - totalSupplied

      return {
        period,
        totalDemand,
        totalSupplied,
        totalUnsupplied,
        supplySources,
      }
    })
    skuDemandAndSupply.push({ sku, periods })
  })

  vintageTransitions = Object.keys(vintageTransitionsObj).map((key) => ({
    vintage: key,
    startDate: vintageTransitionsObj[key].startDate,
    endDate: vintageTransitionsObj[key].endDate,
  }))

  return [skuDemandAndSupply, vintageTransitions]
}

const groupInventoryByVintage = (inventory) => {
  const grouped = {}
  inventory?.forEach((item) => {
    if (!grouped[item.vintage]) {
      grouped[item.vintage] = []
    }
    grouped[item.vintage].push(item)
  })
  return grouped
}

// Blend Group
const DemandItem = ({
  demands,
  inventory,
  expandToggle,
  contractToggle,
  updateBOMInList,
}) => {
  const [expanded, setExpanded] = useState(false)
  const [bomObj, setBOM] = useState(null)
  const [bomEditState, setBOMEditState] = useState({
    periodEdit: false,
    itemsEdit: false,
  })

  const { execute: executePostBOM, isLoading: isSavingBOM } = useFetchWithMsal()

  const saveBOM = async () => {
    if (!bomObj || !bomObj.bom) {
      return
    }

    const resp = await executePostBOM("POST", "/api/demand/bom", bomObj.bom)

    if (resp) {
      updateBOMInList(resp, bomObj.demandID)

      resetBOM()
    }
  }

  const resetBOM = useCallback(() => {
    if (demands && demands.length > 0) {
      let bom =
        demands[0] && demands[0].boms && demands[0].boms.length > 0
          ? demands[0].boms[0]
          : null
      let demandID = demands[0].id

      const soi = demands.find((d) => d.supplyPurposesOnly === true)
      if (soi) {
        bom = soi.boms && soi.boms.length > 0 ? soi.boms[0] : null
        demandID = soi.id
      }

      const bomDeepCopy = bom
        ? {
            ...bom,
            bomPeriods: bom.bomPeriods.map((period) => ({
              ...period,
              bomDetails: period.bomDetails.map((detail) => ({ ...detail })),
            })),
          }
        : null

      if (bom || demandID) {
        setBOM({
          bom: bomDeepCopy,
          demandID: demandID,
        })
      }
      setBOMEditState({
        periodEdit: false,
        itemsEdit: false,
      })
    }
  }, [demands])

  useEffect(() => {
    resetBOM()
  }, [resetBOM])

  useEffect(() => {
    if (expandToggle !== null) {
      setExpanded(true)
    }
  }, [expandToggle])

  useEffect(() => {
    if (contractToggle !== null) {
      setExpanded(false)
    }
  }, [contractToggle])

  const [skuDemandAndSupply] = useMemo(() => {
    return convertToSkuDemandAndSupply(
      demands?.filter((d) => d.sku !== null),
      inventory?.filter((i) => i.sku !== null),
      "sku"
    )
  }, [demands, inventory])

  const blendDemand = useMemo(() => {
    let blendDemand = demands?.filter((d) => !!!d.sku)
    if (skuDemandAndSupply && skuDemandAndSupply.length > 0) {
      const allForecastPeriodsAllSkus = []

      skuDemandAndSupply.forEach((sku) => {
        allForecastPeriodsAllSkus.push(...sku.periods)
      })

      const combinedForecastObj = allForecastPeriodsAllSkus.reduce(
        (acc, item) => {
          const key = item.period
          if (!acc[key]) {
            acc[key] = 0
          }
          acc[key] += item.totalUnsupplied
          return acc
        },
        {}
      )

      const combinedForecast = Object.keys(combinedForecastObj).map((key) => ({
        date: key,
        quantity: combinedForecastObj[key] * NineLiters_TOGALLONS, // SHOULD DO THIS BETTER WAY
      }))

      blendDemand = [
        {
          ...blendDemand[0],
          demandItemForecasts: [
            {
              demandItemForecastDetails: combinedForecast,
            },
          ],
        },
      ]
    }

    return blendDemand
  }, [demands, skuDemandAndSupply])

  const [blendDemandAndSupply, vintageTransitions] = useMemo(() => {
    return convertToSkuDemandAndSupply(
      blendDemand,
      inventory?.filter((i) => !!!i.sku),
      "blend"
    )
  }, [blendDemand, inventory])

  const inventoryGroupedByVintage = useMemo(() => {
    const filteredInventory = inventory?.filter((i) => {
      if (!!!i.sku) {
        return true
      }
      return skuDemandAndSupply.some((sds) => sds.sku === i.sku)
    })
    return groupInventoryByVintage(filteredInventory)
  }, [inventory, skuDemandAndSupply])

  // this will calculate the demand over the bomPeriod date ranges so we can display
  const bomPeriodDemand = useMemo(() => {
    if (
      !bomObj ||
      !bomObj.bom ||
      !bomObj.bom.bomPeriods ||
      !blendDemandAndSupply ||
      blendDemandAndSupply.length === 0
    ) {
      return []
    }

    const demand = []
    bomObj.bom.bomPeriods.forEach((period) => {
      const startDate = ifStringConvertToDate(period.startDate)
      const endDate = ifStringConvertToDate(period.endDate)
      const periodDemand = blendDemandAndSupply[0].periods.filter((p) => {
        const date = ifStringConvertToDate(p.period)
        return date >= startDate && date < endDate
      })
      const totalDemand = periodDemand.reduce(
        (acc, item) => acc + item.totalUnsupplied,
        0
      )
      demand.push({
        period: period.periodName,
        quantity: totalDemand,
      })
    })

    return demand
  }, [bomObj, blendDemandAndSupply])

  return (
    <div className="DemandItem">
      {isSavingBOM && <LinearProgress />}
      <div className="top">
        <Row
          level={"blend"}
          demandSupply={blendDemandAndSupply && blendDemandAndSupply[0]}
          inventory={inventoryGroupedByVintage}
          type={demands && demands[0].type}
          allocationBlend={demands && demands[0].allocationBlend}
          bomType={demands && demands[0].bomType}
          toggleExpanded={() => setExpanded(!expanded)}
          bom={bomObj && bomObj.bom}
          bomDemandId={bomObj && bomObj.demandID}
          updateBOM={(b) =>
            setBOM({
              bom: b,
              demandID: bomObj.demandID,
            })
          }
          bomEditState={bomEditState}
          setBOMEditState={setBOMEditState}
          resetBOM={() => resetBOM()}
          vintageTransitions={vintageTransitions}
          saveBOM={saveBOM}
          bomPeriodDemand={bomPeriodDemand}
        />
      </div>
      {expanded && skuDemandAndSupply?.length > 0 ? (
        <div className="bot">
          {skuDemandAndSupply?.map((demand, index) => (
            <Row
              level={"sku"}
              demandSupply={demand}
              inventory={inventoryGroupedByVintage}
              key={`xdij${index}`}
              type={demands && demands[0].type}
              allocationBlend={demands && demands[0].allocationBlend}
              bomType={demands && demands[0].bomType}
              toggleExpanded={() => setExpanded(!expanded)}
              bomPeriodDemand={bomPeriodDemand}
            />
          ))}
        </div>
      ) : null}
    </div>
  )
}

const DemandSupplyList = ({
  inventory,
  demandsList,
  expandAll,
  contractAll,
  updateBOMInList,
}) => {
  return (
    <div className="DemandList">
      {demandsList?.map((demand, index) => (
        <DemandItem
          expandToggle={expandAll}
          contractToggle={contractAll}
          inventory={inventory[demand.allocationBlend]}
          demands={demand.demands}
          key={`xdij${demand.allocationBlend}`}
          updateBOMInList={updateBOMInList}
        />
      ))}
    </div>
  )
}

export const DemandSupplyPage = () => {
  const navigate = useNavigate()
  let [searchParams, setSearchParams] = useSearchParams()
  const varietalCode = searchParams.get("varietalCode")

  const [pageData, setPageData] = useState(null)
  const [filterState, setFilterState] = useState({
    allocationBlend: null,
    expandAll: null,
    contractAll: null,
  })

  const {
    execute: executeGetData,
    isLoading: dataLoading,
    data,
  } = useFetchWithMsal()

  useEffect(() => {
    executeGetData(
      "GET",
      `/api/demand/supply?varietalCode=${varietalCode || "CAS"}`
    )
  }, [executeGetData, varietalCode])

  useEffect(() => {
    if (data) {
      setPageData(data)
    }
  }, [data])

  const updateBOMInList = (newBOM, demandID) => {
    setPageData({
      ...pageData,
      demands: pageData.demands.map((demand) =>
        demand.id === demandID ? { ...demand, boms: [newBOM] } : demand
      ),
    })
  }

  const inventoryObj = useMemo(
    () => groupInventoryByAllocationBlend(pageData),
    [pageData]
  )

  const demandsGrouped = useMemo(() => {
    // return array of demand items grouped by allocation blend
    const demands = pageData?.demands
    if (!demands) {
      return null
    }

    const grouped = {}
    demands.forEach((demand) => {
      const key = demand.allocationBlend
      if (!grouped[key]) {
        grouped[key] = []
      }
      grouped[key].push(demand)
    })
    return grouped
  }, [pageData])

  const demandsGroupedArr = useMemo(() => {
    return demandsGrouped
      ? Object.keys(demandsGrouped).map((key) => ({
          allocationBlend: key,
          type: demandsGrouped[key][0].type,
          bomType: demandsGrouped[key][0].bomType,
          demands: demandsGrouped[key],
        }))
      : []
  }, [demandsGrouped])

  const demandsFiltered = useMemo(() => {
    return demandsGroupedArr
      ? demandsGroupedArr.filter(
          (demand) =>
            !filterState.allocationBlend ||
            filterState.allocationBlend.length === 0 ||
            filterState.allocationBlend.includes(demand.allocationBlend)
        )
      : []
  }, [demandsGroupedArr, filterState.allocationBlend])

  return (
    <Page fullHeight className="DemandSupply">
      {dataLoading ? (
        <div className="loadingBar">
          <LinearProgress />
        </div>
      ) : null}
      <div className="cont">
        <div className="ribbon">
          <Stack direction="row" spacing={1} alignItems={"center"} flex={1}>
            <Button
              onClick={() =>
                setFilterState({
                  ...filterState,
                  expandAll: !filterState.expandAll,
                })
              }
              size="small"
              variant="outlined"
              startIcon={<UnfoldMoreIcon />}
            >
              All
            </Button>
            <Button
              onClick={() =>
                setFilterState({
                  ...filterState,
                  contractAll: !filterState.contractAll,
                })
              }
              size="small"
              variant="outlined"
              startIcon={<UnfoldLessIcon />}
            >
              All
            </Button>
            <div>
              <select
                value={varietalCode || "CAS"}
                onChange={(e) => {
                  setSearchParams((current) => {
                    const searchParams = new URLSearchParams(current)
                    searchParams.set("varietalCode", e.target.value)
                    return `?${searchParams.toString()}`
                  })
                }}
              >
                {pageData?.allDistinctVarietalCodes?.map((code) => (
                  <option key={code} value={code}>
                    {code}
                  </option>
                ))}
              </select>
            </div>
            <Autocomplete
              id="allocationBlendSelect"
              sx={{ flex: 1, width: "100%", minWidth: "200px" }}
              size="small"
              disablePortal={true}
              disableCloseOnSelect={true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Blends"
                  slotprops={{
                    htmlInput: {
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    },
                  }}
                />
              )}
              multiple
              limitTags={2}
              value={filterState.allocationBlend || []}
              onChange={(event, newValue) => {
                setFilterState({
                  ...filterState,
                  allocationBlend: newValue,
                })
              }}
              options={
                Object.keys(demandsGrouped || {}).map((key) => key) || []
              }
            />
          </Stack>
          <Stack direction="row" spacing={1}>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => navigate("/demand/cg")}
              endIcon={<OpenInNewIcon />}
            >
              Demands
            </Button>
          </Stack>
        </div>
        <div className="bodyCont">
          <DemandSupplyList
            expandAll={filterState.expandAll}
            contractAll={filterState.contractAll}
            inventory={inventoryObj}
            demandsList={demandsFiltered}
            updateBOMInList={updateBOMInList}
          />
        </div>
      </div>
    </Page>
  )
}
